import { DateTime } from 'luxon'

export const FULL_DAY_SECONDS = 24 * 60 * 60

export function secondsDuration(
  startSeconds: number,
  endSeconds: number,
): number {
  return endSeconds > startSeconds
    ? endSeconds - startSeconds
    : FULL_DAY_SECONDS - startSeconds + endSeconds
}

export function secondsToStartEnd({
  startDate,
  startSeconds,
  endSeconds,
  timezone,
}: {
  startDate: DateTime
  startSeconds: number
  endSeconds: number
  timezone: string
}) {
  const durationSeconds = secondsDuration(startSeconds, endSeconds)

  const startAt = startDate
    .plus({ seconds: startSeconds })
    .setZone(timezone, { keepLocalTime: true })
  const endAt = startAt.plus({ seconds: durationSeconds })

  return { startAt, endAt }
}
