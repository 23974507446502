import { TimelineViewSettings } from './types'

export const TIMELINE_VIEW_SETTINGS_MODAL_KEY = 'timelineViewSettingsModal'
export const SET_TIMELINE_VIEW_SETTINGS_MODAL = 'timelineViewSettingsModal/set'

export const DEFAULT_VIEW_SETTINGS: TimelineViewSettings = {
  shift: true,
  roleAndDepartment: true,
  timeEntries: true,
  timeoffs: true,
  availabilities: true,
  workPackages: true,
  unassignedWorkPackages: true,
}
