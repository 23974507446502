import { useApolloQuery } from 'API/services/Apollo'

import {
  EmployeeTimeEntryTotalsQuery,
  EmployeeTimeEntryTotalsQueryData,
} from './GraphQL'

type Args = {
  filter: Gateway.TimeEntryFilter
}

export function useEmployeeTimeEntryTotals({ filter }: Args) {
  const { data, loading, refetch } = useApolloQuery<
    EmployeeTimeEntryTotalsQueryData,
    Gateway.QueryEmployeeTimeEntryTotalsArgs
  >(EmployeeTimeEntryTotalsQuery, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
  })

  const counts = data?.employeeTimeEntryTotals

  return {
    counts,
    loading,
    refetch,
  }
}
