import { useState } from 'react'

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  EmployeeSchedulesByCursorQuery,
  EmployeeSchedulesByCursorQueryData,
} from '../GraphQL'

type Props = {
  employeeFilter: Gateway.EmployeeScheduleEmployeeFilter
  scheduleFilter: Gateway.EmployeeScheduleScheduleFilter
  paging: CursorPage
  sorting?: Gateway.EmployeeScheduleSorting
  directives: {
    includeWorkPackageChunks?: boolean
    includeTimeEntries?: boolean
  }
  options?: Pick<QueryHookOptions, 'pollInterval'>
}

export function useEmployeeSchedulesByCursor({
  employeeFilter,
  scheduleFilter,
  paging,
  sorting,
  directives,
  options,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, fetchMore, refetch } = useApolloQuery<
    EmployeeSchedulesByCursorQueryData,
    Gateway.QueryEmployeeSchedulesByCursorArgs & {
      includeWorkPackageChunks: boolean
      includeTimeEntries: boolean
    }
  >(EmployeeSchedulesByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      employeeFilter,
      scheduleFilter,
      paging: cursorPageToGraphqlPaging(paging),
      sorting,
      includeWorkPackageChunks: Boolean(directives.includeWorkPackageChunks),
      includeTimeEntries: Boolean(directives.includeTimeEntries),
    },
    onCompleted(data) {
      const newPageMeta = data.employeeSchedulesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
    ...(options?.pollInterval && { pollInterval: options.pollInterval }),
  })

  const employeeSchedules =
    data?.employeeSchedulesByCursor?.edges.map(edge => edge.node) ?? []

  const loadMore = async () => {
    setLoadingMore(true)

    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setLoadingMore(false)
  }

  return {
    refetch,
    employeeSchedules,
    loading: loading && !loadingMore,
    pageMeta: currentPageMeta,
    loadingMore,
    loadMore,
  }
}
