import styled from 'styled-components'
import { flexbox, grid, layout, space } from 'styled-system'

import { CommonProps } from './types'

export type ContentProps = CommonProps

export const Content = styled.div<ContentProps>`
  margin: 24px;

  border: 1px solid ${({ theme }) => theme.layout.border};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.layout.bg};

  ${space};
  ${layout};
  ${flexbox};
  ${grid};
`
