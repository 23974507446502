import React, { useCallback, useState } from 'react'
import withClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Button from './Button'

const SelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
const SelectButtonTitle = styled.div`
  margin-right: 5px;
`

const SelectButtonWindow = styled.div`
  background-color: ${themeGet('colors.WHITE')};
  position: absolute;
  top: 24px;
`

function SelectButton({ buttons, title, width, ...rest }) {
  const [opened, setOpened] = useState(false)

  const toggleOpened = useCallback(() => {
    setOpened(!opened)
  }, [opened, setOpened])

  const closeSelect = useCallback(() => {
    setOpened(false)
  }, [setOpened])

  SelectButton.handleClickOutside = () => closeSelect()

  const createButtonClickHandler = useCallback(
    btn => () => {
      btn.onClick()
      setOpened(false)
    },
    [],
  )

  return (
    <SelectButtonWrapper>
      <Button {...rest} width={width} onClick={toggleOpened}>
        <SelectButtonTitle>{title}</SelectButtonTitle>
        {opened ? <FiChevronUp /> : <FiChevronDown />}
      </Button>
      {opened && (
        <SelectButtonWindow>
          {buttons
            .filter(btn => !btn.disabled)
            .map(btn => (
              <Button
                key={btn.title}
                mt={1}
                outline
                small
                width={width}
                onClick={createButtonClickHandler(btn)}
              >
                {btn.title}
              </Button>
            ))}
        </SelectButtonWindow>
      )}
    </SelectButtonWrapper>
  )
}

SelectButton.propTypes = {
  ...Button.propTypes,
  buttons: PropTypes.array.isRequired,
}

SelectButton.defaultProps = {
  ...Button.defaultProps,
  buttons: [],
}

export default withClickOutside(SelectButton, {
  handleClickOutside: () => SelectButton.handleClickOutside,
})
