import { DateTime } from 'luxon'

export function isTimeoffStartDateInThePast({
  startISODate,
  startISOTime,
  partial,
  timezone,
}: {
  startISODate?: string
  startISOTime?: string
  partial?: boolean
  timezone: string
}) {
  if (!startISODate) return false

  if (partial && startISOTime) {
    return (
      DateTime.fromISO(startISOTime, {
        zone: timezone,
      }) < DateTime.now().setZone(timezone, { keepLocalTime: true })
    )
  }

  return (
    DateTime.fromISO(startISODate, { zone: timezone }).startOf('day') <
    DateTime.now().setZone(timezone, { keepLocalTime: true }).startOf('day')
  )
}

export function wholeRange(
  startAtDate: string,
  endAtDate: string,
  /** @description timezone of the employee the timeoff belongs to */
  timezone: string,
) {
  const start = DateTime.fromISO(startAtDate).setZone(timezone).startOf('day')
  const end = DateTime.fromISO(endAtDate).setZone(timezone).endOf('day')

  return [start, end]
}

export function partialRange(
  startAt: string,
  endAt: string,
  /** @description timezone of the employee the timeoff belongs to */
  timezone: string,
) {
  const start = DateTime.fromISO(startAt).setZone(timezone)
  const end = DateTime.fromISO(endAt).setZone(timezone)

  return [start, end]
}
