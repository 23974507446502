import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import {
  WorkPackageChunksCountByDaysQuery,
  WorkPackageChunksCountByDaysQueryData,
} from './GraphQL'

type QueryArgs = {
  day1: Gateway.WorkPackageChunksFilter
  day2: Gateway.WorkPackageChunksFilter
  day3: Gateway.WorkPackageChunksFilter
  day4: Gateway.WorkPackageChunksFilter
  day5: Gateway.WorkPackageChunksFilter
  day6: Gateway.WorkPackageChunksFilter
  day7: Gateway.WorkPackageChunksFilter
}

type Props = {
  filter: QueryArgs
  options?: Pick<QueryHookOptions, 'skip' | 'pollInterval'>
}

// NOTE: it's hardcoded to 7 days, in future we can add new method to GW and BE to handle it with 1 filter and with day in response
export function useWorkPackageChunksCountByDays({ filter, options }: Props) {
  const { data, loading } = useApolloQuery<
    WorkPackageChunksCountByDaysQueryData,
    QueryArgs
  >(WorkPackageChunksCountByDaysQuery, {
    variables: { ...filter },
    ...options,
  })

  return {
    count: data,
    loading,
  }
}
