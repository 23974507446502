import { useWorkPackageChunk } from './useWorkPackageChunk'
import { useWorkPackageChunksByCursor } from './useWorkPackageChunksByCursor'
import { useWorkPackageChunksCount } from './useWorkPackageChunksCount'
import { useWorkPackageChunksCountByDays } from './useWorkPackageChunksCountByDays'

export const WorkPackageChunkAPI = {
  byId: useWorkPackageChunk,
  byCursor: useWorkPackageChunksByCursor,
  count: useWorkPackageChunksCount,
  countByDays: useWorkPackageChunksCountByDays,
}
