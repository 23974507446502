import { StoreonModule } from 'storeon'

import { INIT } from 'services/StoreOn/constants'

import {
  DEFAULT_VIEW_SETTINGS,
  SET_TIMELINE_VIEW_SETTINGS_MODAL,
  TIMELINE_VIEW_SETTINGS_MODAL_KEY,
} from './constants'
import {
  TimelineViewSettingsModalEvents,
  TimelineViewSettingsModalState,
} from './types'

const INITIAL_STATE: TimelineViewSettingsModalState = {
  [TIMELINE_VIEW_SETTINGS_MODAL_KEY]: {
    viewSettings: DEFAULT_VIEW_SETTINGS,
  },
}

export const timelineViewSettingsModalModule: StoreonModule<
  TimelineViewSettingsModalState,
  TimelineViewSettingsModalEvents
> = store => {
  store.on(INIT, () => INITIAL_STATE)

  store.on(SET_TIMELINE_VIEW_SETTINGS_MODAL, (state, payload) => ({
    ...state,
    [TIMELINE_VIEW_SETTINGS_MODAL_KEY]: {
      ...state[TIMELINE_VIEW_SETTINGS_MODAL_KEY],
      viewSettings: {
        ...state[TIMELINE_VIEW_SETTINGS_MODAL_KEY].viewSettings,
        ...payload,
      },
    },
  }))
}
