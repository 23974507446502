import { useApproveTimeCard } from './useApproveTimeCard'
import { useApprovedTimeEntries } from './useApproveTimeEntries'
import { useCreateTimeCard } from './useCreateTimeCard'
import { useCreateTimeEntries } from './useCreateTimeEntries'
import { useCreateUpdateTimeEntries } from './useCreateUpdateTimeEntries'
import { useDeleteTimeEntries } from './useDeleteTimeEntries'
import { useDiscardTimeEntries } from './useDiscardTimeEntries'
import { useEmployeeTimeEntryTotals } from './useEmployeeTimeEntryTotals'
import { useExportTimeCards } from './useExportTimeCards'
import { useRestoreTimeEntries } from './useRestoreTimeEntries'
import { useTimeCardsByCursor } from './useTimeCardsByCursor'
import { useTimeCardsByOffset } from './useTimeCardsByOffset'
import { useTimeCardsInfinite } from './useTimeCardsInfinite'
import { useTimeEntriesByCursor } from './useTimeEntriesByCursor'
import { useTimeEntrySummariesByCursor } from './useTimeEntrySummariesByCursor'
import { useUnapproveTimeCard } from './useUnapproveTimeCard'
import { useUpdateTimeEntries } from './useUpdateTimeEntries'

export * from './constants'

export const TimeCardAPI = {
  create: useCreateTimeCard,
  byOffset: useTimeCardsByOffset,
  byCursor: useTimeCardsByCursor,
  infinite: useTimeCardsInfinite,
  approve: useApproveTimeCard,
  unapprove: useUnapproveTimeCard,

  extract: useExportTimeCards,
  byCursorGql: useTimeEntriesByCursor,
  summariesByCursor: useTimeEntrySummariesByCursor,
  createMany: useCreateTimeEntries,
  updateMany: useUpdateTimeEntries,
  createUpdateMany: useCreateUpdateTimeEntries,
  discardMany: useDiscardTimeEntries,
  approveMany: useApprovedTimeEntries,
  restoreMany: useRestoreTimeEntries,
  deleteMany: useDeleteTimeEntries,
  employeeTotals: useEmployeeTimeEntryTotals,
}
