import CreateShiftTagMutation from './Mutations/CreateShiftTag.graphql'
import DeleteShiftTagMutation from './Mutations/DeleteShiftTag.graphql'
import UpdateShiftTagMutation from './Mutations/UpdateShiftTag.graphql'
import ShiftTagsByOffsetQuery from './Queries/ShiftTagsByOffset.graphql'

export * from './Fragments/types'

export {
  CreateShiftTagMutation,
  DeleteShiftTagMutation,
  ShiftTagsByOffsetQuery,
  UpdateShiftTagMutation,
}
