import React from 'react'
import { components, ValueContainerProps } from 'react-select'

import styled from 'styled-components'

import { SelectOption } from 'Types/common'

const ValueContainerWrapper = styled.div`
  flex: 1;
  max-height: 80px;
  overflow: auto;
`

export function ValueContainer(
  props: ValueContainerProps<SelectOption, false>,
) {
  return (
    <ValueContainerWrapper>
      <components.ValueContainer {...props} />
    </ValueContainerWrapper>
  )
}
