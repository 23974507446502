import React, { useState } from 'react'

import { API } from 'API'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__/EmployeePicker'
import { useShiftAssignmentContext } from 'components/blocks/__v3__/ShiftAssignment/context'
import { Table } from 'components/ui/__v3__'

import { PAGE_SIZE } from 'constants/pagination'

import { useCursorPagination } from 'hooks'

import { UNAVAILABLE_QUALIFIED_EMPLOYEES_OPTIONS_PAYLOAD } from './constants'
import { reasonToOptions } from './helpers'

import { sortByBid } from '../../../../helpers'
import { useCandidatesTable } from '../../hooks'
import { CandidatesTabContainer } from '../../styles'
import { CandidatesFilterFormState, UnavailabilityReasons } from '../../types'

const CANDIDATES_PAGE_SIZE = PAGE_SIZE[10]

type Props = {
  customCvFields: CandidatesFilterFormState['customCvFields']
  reason: UnavailabilityReasons
  showQualifiedEmployees?: boolean
}

export function UnavailableTab({
  customCvFields,
  reason,
  showQualifiedEmployees = false,
}: Props) {
  const {
    selectedShiftJob,
    draftAssign,
    isDraftAssigned,
    missingAssignments,
    revertDraftAssignment,
  } = useShiftAssignmentContext()

  const { page, onShowAfter, onShowBefore } = useCursorPagination(
    CANDIDATES_PAGE_SIZE,
  )

  const [employee, setEmployee] = useState<
    EmployeePickerReturnOption | undefined
  >(undefined)

  const {
    availableEmployees,
    pageInfo,
    loading,
  } = API.Employee.availableForShiftByCursor(
    {
      shiftJobId: selectedShiftJob?.id ?? '',
      options: showQualifiedEmployees
        ? UNAVAILABLE_QUALIFIED_EMPLOYEES_OPTIONS_PAYLOAD
        : reasonToOptions(reason),
      filter: { employee, customCvFields },
      paging: {
        limit: page.size,
        startingAfter: page.startingAfter,
        endingBefore: page.endingBefore,
      },
    },
    { skip: !selectedShiftJob?.id },
  )

  const table = useCandidatesTable({
    candidates: sortByBid(availableEmployees),
    isDraftAssigned,
    cantAssignMore: missingAssignments === 0,
    filters: { employee },
    filterHandlers: { employee: option => setEmployee(option ?? undefined) },
    handlers: {
      onAssign: draftAssign,
      onRevertAssignment: revertDraftAssignment,
    },
  })

  return (
    <CandidatesTabContainer>
      <Table.Container maxHeight="400px">
        <Table.Element>
          <Table.Header table={table} />
          <Table.Body loading={loading} table={table} />
        </Table.Element>
      </Table.Container>
      {pageInfo && (
        <Table.Pagination.Cursor
          hasNextPage={Boolean(pageInfo.hasNextPage)}
          hasPrevPage={Boolean(pageInfo.hasPreviousPage)}
          size={page.size}
          onNextPageClick={() => onShowAfter(pageInfo.endCursor)}
          onPrevPageClick={() => onShowBefore(pageInfo.startCursor)}
        />
      )}
    </CandidatesTabContainer>
  )
}
