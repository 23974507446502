interface Subscription {
  event: string
  callback: (...args: any[]) => void
  context: any
}

type Subscriptions = Subscription[]

const actions = {
  setPageTitle: 'setPageTitle',
  resetActionHeaderState: 'resetActionHeaderState',
  employeeScheduleModified: 'employeeScheduleModified',
  timeoffAssigned: 'timeoffAssigned',
}

const EventBus = (() => {
  let subscriptions: Subscriptions = []

  return {
    on(event: string, callback: (...args: any[]) => void, context: any = null) {
      subscriptions.push({
        event,
        callback,
        context,
      })
    },

    off(event: string, callback: (...args: any[]) => void) {
      subscriptions = subscriptions.filter(
        subscription =>
          subscription.event !== event || subscription.callback !== callback,
      )
    },

    trigger(action: string, ...args: any[]) {
      const event = Object.keys(actions).find(
        key => actions[key as keyof typeof actions] === action,
      )
      if (event) {
        const eventSubscriptions = subscriptions.filter(
          subscription => subscription.event === event,
        )

        for (const { callback, context } of eventSubscriptions) {
          callback.call(context, ...args)
        }
      }
    },
    actions,
  }
})()

export default EventBus
