import {
  AvailableEmployeesForShiftJobCheck,
  AvailableEmployeesForShiftJobOrCondition,
} from 'constants/gatewayGraphQL'

export const UNAVAILABLE_QUALIFIED_EMPLOYEES_OPTIONS_PAYLOAD = {
  shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
  timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
  availabilityConflicts: AvailableEmployeesForShiftJobCheck.True,
  missingQualifications: AvailableEmployeesForShiftJobCheck.False,
  orConditions: [
    AvailableEmployeesForShiftJobOrCondition.ShiftConflicts,
    AvailableEmployeesForShiftJobOrCondition.TimeoffConflicts,
    AvailableEmployeesForShiftJobOrCondition.AvailabilityConflicts,
  ],
}
