import { Content } from './Content'
import { Header } from './Header'
import { HeaderV2 } from './HeaderV2'
import { Layout } from './Layout'
import { Title } from './Title'

export const Page = {
  Layout,
  Title,
  Content,
  Header,
  HeaderV2,
}
