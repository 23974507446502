import { useApolloClient } from '@apollo/client'
import { useApolloHeaders, useApolloLazyQuery } from 'API/services/Apollo'

import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import {
  EmployeeWithProfileFields,
  SuggestedEmployeesForCoverPickerQuery,
  SuggestedEmployeesForCoverPickerQueryData,
} from '../GraphQL'

export function useSuggestedEmployeesForCoverPicker() {
  const client = useApolloClient()
  const headers = useApolloHeaders()

  const [
    loadInner,
    { loading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    SuggestedEmployeesForCoverPickerQueryData,
    Gateway.QuerySuggestedEmployeesForCoverByCursorArgs
  >(SuggestedEmployeesForCoverPickerQuery, {
    fetchPolicy: 'network-only',
  })

  const loadSuggestedEmployeesForCover = ({
    scheduleId,
    inputValue,
  }: {
    scheduleId: string
    inputValue?: string
  }) => {
    loadInner({
      variables: {
        paging: { limit: PAGE_SIZE['10'] },
        scheduleId,
        filter: {
          ...(inputValue && {
            nameOrCode: Utils.GraphQL.ilikeFilter(inputValue),
          }),
        },
      },
    })
  }

  const loadSuggestedEmployeesForCoverPromise = async ({
    scheduleId,
    inputValue,
  }: {
    scheduleId?: string
    inputValue?: string
  }) => {
    if (!scheduleId) return []

    const result = await client.query<
      SuggestedEmployeesForCoverPickerQueryData,
      Gateway.QuerySuggestedEmployeesForCoverByCursorArgs
    >({
      context: {
        headers,
      },
      query: SuggestedEmployeesForCoverPickerQuery,
      variables: {
        scheduleId,
        paging: { limit: PAGE_SIZE['10'] },
        filter: {
          nameOrCode: inputValue
            ? Utils.GraphQL.ilikeFilter(inputValue)
            : undefined,
        },
      },
      fetchPolicy: 'network-only',
    })

    return dataToNodes(result.data)
  }

  const refetchSuggestedEmployeesForCover = async ({
    inputValue,
  }: {
    inputValue?: string
  } = {}) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: {
        nameOrCode: inputValue
          ? Utils.GraphQL.ilikeFilter(inputValue)
          : undefined,
      },
    })

    return dataToNodes(result.data)
  }

  const suggestedEmployeesForCover = dataToNodes(data)

  return {
    suggestedEmployeesForCover,
    suggestedEmployeesForCoverLoading: loading,
    refetchSuggestedEmployeesForCover,
    loadSuggestedEmployeesForCover,
    loadSuggestedEmployeesForCoverPromise,
  }
}

function dataToNodes(
  data?: SuggestedEmployeesForCoverPickerQueryData,
): EmployeeWithProfileFields[] {
  return (
    data?.suggestedEmployeesForCoverByCursor?.edges.map(edge => edge.node) ?? []
  )
}
