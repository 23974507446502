import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import { WorkPackageChunksCountQuery } from './GraphQL/Queries'

type Props = {
  filter: Gateway.WorkPackageChunksFilter
  options?: Pick<QueryHookOptions, 'skip' | 'pollInterval'>
}

export function useWorkPackageChunksCount({ filter, options }: Props) {
  const { data, loading } = useApolloQuery<
    QueryData<'workPackageChunksCount'>,
    Gateway.QueryWorkPackageChunksCountArgs
  >(WorkPackageChunksCountQuery, {
    variables: { filter },
    ...options,
  })

  return {
    count: data?.workPackageChunksCount,
    loading,
  }
}
