import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]}px;
  width: 230px;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.COOL_GRAY};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`
