import styled from 'styled-components'
import { flex, grid, layout, space } from 'styled-system'

import { CommonProps } from './types'

type ContentProps = CommonProps

// TODO: instead of using it on every page, after refactor wrap whole router
export const Layout = styled.div<ContentProps>`
  background-color: ${({ theme }) => theme.layout.blueishBg};

  overflow-y: auto;
  height: calc(100vh - ${({ theme }) => theme.sizes.header});
  width: 100%;

  ${space};
  ${layout};
  ${flex}
  ${grid};
`
