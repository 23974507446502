import { useCallback, useMemo } from 'react'

import { useStoreon } from 'storeon/react'

import {
  SET_TIMELINE_VIEW_SETTINGS_MODAL,
  TIMELINE_VIEW_SETTINGS_MODAL_KEY,
} from './constants'
import {
  TimelineViewSettingsModalEvents,
  TimelineViewSettingsModalState,
  TimelineViewSettingsPayload,
} from './types'

export const useTimelineViewSettingsModal = () => {
  const { [TIMELINE_VIEW_SETTINGS_MODAL_KEY]: state, dispatch } = useStoreon<
    TimelineViewSettingsModalState,
    TimelineViewSettingsModalEvents
  >(TIMELINE_VIEW_SETTINGS_MODAL_KEY)

  const setViewSettings = useCallback(
    (variables?: TimelineViewSettingsPayload) =>
      dispatch(SET_TIMELINE_VIEW_SETTINGS_MODAL, variables),
    [dispatch],
  )

  const viewSettings = useMemo(() => state.viewSettings, [state])

  return {
    setViewSettings,
    viewSettings,
  }
}
