import React from 'react'
import { useFormContext } from 'react-hook-form'

import { FormEmployeePicker } from 'components/blocks/__v3__/HookForm'
import { Flex } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { FormDayRangePicker } from 'components/ui/__v3__/HookForm'

import { useI18n } from 'hooks/useI18n'

import { TRANSLATIONS } from 'i18n'

import { Section, Title } from './styles'

import { TimeClassificationSimulationFormState } from '../../types'

type Props = {
  disableEdit: boolean
  onSubmit: () => void
  isValid: boolean
  simulating: boolean
}

export function Content({ disableEdit, onSubmit, isValid, simulating }: Props) {
  const t = useI18n<typeof TRANSLATIONS.timeClassificationSimulationModal>(
    'timeClassificationSimulationModal',
  )

  const { control } = useFormContext<TimeClassificationSimulationFormState>()

  return (
    <Flex flexDirection="column" gap={4} justifyContent="space-between">
      <Flex gap={4} justifyContent="space-between" mt={4} width="100%">
        <Section>
          <Title>{t('employee')}</Title>
          <FormEmployeePicker
            control={control}
            isDisabled={disableEdit}
            name="employee"
            required
          />
        </Section>

        <Section>
          <Title>{t('period')}</Title>
          <FormDayRangePicker
            control={control}
            disabled={disableEdit}
            maxSelectionLength="1 month"
            name="period"
            required
            width="100%"
          />
        </Section>
      </Flex>

      {!disableEdit && (
        <Button
          alignSelf="end"
          disabled={!isValid || simulating}
          width={140}
          onClick={onSubmit}
        >
          {t('actions.runSimulation')}
        </Button>
      )}
    </Flex>
  )
}
