// TODO: Need to clean up to only use the minimum  gateway types

import { DateTime } from 'luxon'

import { SHIFT_ALREADY_STARTED_THRESHOLD } from 'constants/shifts'

type BaseShift = Pick<
  Gateway.Shift,
  'range' | 'unfilledPositions' | 'totalPositions'
>

type BaseShiftWithLocation = BaseShift & {
  location: {
    settings: Pick<Gateway.LocationSettings, 'timezone'>
  }
}

type BaseShiftWithBranch = Omit<BaseShift, 'range'> & {
  branch: {
    settings: Pick<Gateway.LocationSettings, 'timezone'>
  }
  startAt?: string
  endAt?: string
}

type Shift = BaseShiftWithLocation | BaseShiftWithBranch

function isShiftWithLocation(
  shift: BaseShiftWithLocation | BaseShiftWithBranch,
): shift is BaseShiftWithLocation {
  return (shift as BaseShiftWithLocation).location !== undefined
}

function getTimezone(shift: Shift): string {
  return isShiftWithLocation(shift)
    ? shift.location.settings.timezone
    : shift.branch.settings.timezone
}

export function getStart(shift: Shift): DateTime {
  if (isShiftWithLocation(shift)) {
    return DateTime.fromISO(shift.range.start).setZone(
      shift.location.settings.timezone,
    )
  }

  return DateTime.fromISO(shift.startAt ?? '').setZone(
    shift.branch.settings.timezone,
  )
}

export function getEnd(shift: Shift): DateTime {
  if (isShiftWithLocation(shift)) {
    return DateTime.fromISO(shift.range.end).setZone(
      shift.location.settings.timezone,
    )
  }

  return DateTime.fromISO(shift.endAt ?? '').setZone(
    shift.branch.settings.timezone,
  )
}

export function didEnd(shift: Shift): boolean {
  const timezone = getTimezone(shift)

  return getEnd(shift) < DateTime.now().setZone(timezone)
}

export function didStart(shift: Shift): boolean {
  const timezone = getTimezone(shift)

  return getStart(shift) < DateTime.now().setZone(timezone)
}

export function didPassShiftThreshold(
  shift: Shift,
  timezone?: string,
): boolean {
  const shiftTimezone = getTimezone(shift)
  const now = DateTime.now().setZone(timezone ?? shiftTimezone)

  const shiftStart = getStart(shift)
  const diff = now.diff(shiftStart, 'minutes').toObject().minutes

  return !((diff ?? 0) <= SHIFT_ALREADY_STARTED_THRESHOLD)
}

export function didStartAndDidNotEnd(shift: Shift): boolean {
  return didStart(shift) && !didEnd(shift)
}

export function containEmployees(shift: BaseShift) {
  return shift.unfilledPositions !== shift.totalPositions
}

export const isShiftAlreadyStartedByTime = (
  shiftStartAt: string | DateTime,
): boolean => {
  const shiftStartTime =
    typeof shiftStartAt === 'string'
      ? DateTime.fromISO(shiftStartAt)
      : shiftStartAt

  const nowTime = DateTime.local()
  const timesDiff = nowTime.diff(shiftStartTime, 'minutes').toObject().minutes

  return Number(timesDiff) >= 0
}
