import React, { useMemo } from 'react'

import { InfoPopover } from 'components/blocks/__v2__'
import { useShiftAssignmentContext } from 'components/blocks/__v3__/ShiftAssignment/context'
import { Divider } from 'components/ui/__v2__/Divider'
import { Span } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { AvailableTooltipContent } from './styles'

import { CandidateTab } from '../../types'

type Props = {
  tab: CandidateTab
}

export function SelectInfoPopover({ tab }: Props) {
  const t = useI18n<typeof TRANSLATIONS.shiftAssign.candidates>(
    'shiftAssign.candidates',
  )

  const { availabilityAsHardConstraint } = useShiftAssignmentContext()

  const isAvailable = tab === 'available'
  const isAvailableMissingQualifications =
    tab === 'availableMissingQualifications'
  const isUnavailableQualified = tab === 'unavailableQualified'

  const popoverContent = useMemo(() => {
    if (
      !isAvailable &&
      !isUnavailableQualified &&
      !isAvailableMissingQualifications
    ) {
      return null
    }

    const groupQualificationsText = isAvailableMissingQualifications
      ? t('availableDetails.doNotHaveGroupQualifications')
      : t('availableDetails.hasGroupQualifications')

    if (isAvailable || isAvailableMissingQualifications) {
      return (
        <>
          <Span>{groupQualificationsText}</Span>
          <Span>{t('availableDetails.notHavingShiftConflicts')}</Span>
          <Span>{t('availableDetails.notHavingTimeoffConflicts')}</Span>
          {availabilityAsHardConstraint && (
            <Span>{t('availableDetails.notHavingAvailabilityConflicts')}</Span>
          )}
        </>
      )
    }

    // If unavailableQualified
    return (
      <>
        <Span>{groupQualificationsText}</Span>

        <Span fontStyle="italic">
          {t('unavailableDetails.reasons.whateverReason')}:
        </Span>
        <Span>{t('unavailableDetails.reasons.shiftConflict')}</Span>
        <Span>
          {t('unavailableDetails.reasons.or')}{' '}
          {t('unavailableDetails.reasons.timeoffConflict')}
        </Span>
        {availabilityAsHardConstraint && (
          <Span>
            {t('unavailableDetails.reasons.or')}{' '}
            {t('unavailableDetails.reasons.availabilityConflict')}
          </Span>
        )}
      </>
    )
  }, [
    isAvailable,
    isAvailableMissingQualifications,
    isUnavailableQualified,
    availabilityAsHardConstraint,
    t,
  ])

  if (
    !isAvailable &&
    !isUnavailableQualified &&
    !isAvailableMissingQualifications
  ) {
    return null
  }

  return (
    // TODO: replace with new Popover component
    // @ts-ignore
    <InfoPopover
      popoverContent={
        <AvailableTooltipContent>
          <Span mb={1}>{t('availableDetails.description')}</Span>
          <Divider />

          {popoverContent}
        </AvailableTooltipContent>
      }
      trigger="click"
    />
  )
}
