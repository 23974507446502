import { AiOutlineCheck } from 'react-icons/ai'

import styled from 'styled-components'

export const Timesheet = {
  Container: styled.div`
    display: flex;
    flex-direction: column;

    border: 1px solid ${({ theme }) => theme.colors.SILVER_GRAY};
    border-radius: 4px;
  `,

  Form: styled.div`
    display: grid;
    padding: 8px ${({ theme }) => theme.spacing.padding.md};
    grid-template-columns: minmax(154px, auto) 124px 124px 56px 120px 100px 88px 100px 80px;
    grid-gap: ${({ theme }) => theme.space[1]}px;

    background: ${({ theme }) => theme.colors.ATHENS_GRAY_20};
  `,

  Timeoff: styled.div`
    display: grid;
    padding: 8px ${({ theme }) => theme.spacing.padding.md} 4px;
    grid-template-columns: 200px 158px 88px 88px 88px 80px auto;
    grid-gap: ${({ theme }) => theme.spacing.padding.lg};

    background: ${({ theme }) => theme.colors.ATHENS_GRAY_20};
  `,

  Footer: styled.div`
    display: flex;
    padding: 6px ${({ theme }) => theme.spacing.padding.md};
    justify-content: space-between;
    align-items: center;
  `,
}

export const CheckIcon = styled(AiOutlineCheck)`
  color: ${({ theme }) => theme.toasts.secondaryColor.success};
`
