import {
  AvailableEmployeesForShiftJobCheck,
  AvailableEmployeesForShiftJobOrCondition,
} from 'constants/gatewayGraphQL'

import { UnavailabilityReasons } from '../../types'

export function reasonToOptions(
  reason: UnavailabilityReasons,
): Gateway.AvailableEmployeesForShiftJobOptions {
  switch (reason) {
    case UnavailabilityReasons.AvailabilityConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.True,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        missingQualifications: AvailableEmployeesForShiftJobCheck.Skip,
      }
    case UnavailabilityReasons.AvailableButShiftConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.False,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        missingQualifications: AvailableEmployeesForShiftJobCheck.Skip,
      }
    case UnavailabilityReasons.AvailableBustTimeoffConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.False,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
        missingQualifications: AvailableEmployeesForShiftJobCheck.Skip,
      }
    case UnavailabilityReasons.Whatever:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.True,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
        missingQualifications: AvailableEmployeesForShiftJobCheck.True,
        orConditions: [
          AvailableEmployeesForShiftJobOrCondition.AvailabilityConflicts,
          AvailableEmployeesForShiftJobOrCondition.ShiftConflicts,
          AvailableEmployeesForShiftJobOrCondition.TimeoffConflicts,
          AvailableEmployeesForShiftJobOrCondition.MissingQualifications,
        ],
      }
    case UnavailabilityReasons.ShiftConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        missingQualifications: AvailableEmployeesForShiftJobCheck.Skip,
      }
    case UnavailabilityReasons.TimeoffConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
        missingQualifications: AvailableEmployeesForShiftJobCheck.Skip,
      }
    case UnavailabilityReasons.AvailableButMissingQualifications:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        missingQualifications: AvailableEmployeesForShiftJobCheck.True,
      }
    default:
      throw new Error(`Unknown reason: ${reason}`)
  }
}
