import styled from 'styled-components'

export const TimeType = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${({ theme }) => theme.space[2]}px;
    padding: 12px 16px 16px 16px;
    border: 1px solid ${({ theme }) => theme.colors.SILVER_GRAY};
    border-radius: 4px;
  `,
  Title: styled.span`
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  `,
  Value: styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  `,
}
