import { useApolloQuery } from 'API/services/Apollo'
import {
  WorkPackageChunkQuery,
  WorkPackageChunkQueryData,
} from 'API/WorkPackageChunk/GraphQL'

export function useWorkPackageChunk(id: string) {
  const { data, loading, error } = useApolloQuery<
    WorkPackageChunkQueryData,
    Gateway.QueryWorkPackageChunkArgs
  >(WorkPackageChunkQuery, {
    variables: { id },
  })

  return {
    workPackageChunk: data?.workPackageChunk,
    loading,
    error,
  }
}
