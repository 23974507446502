import { useState } from 'react'

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  WorkPackageChunkByCursorQueryData,
  WorkPackageChunksByCursorQuery,
} from './GraphQL/Queries'

type Props = {
  filter: Gateway.WorkPackageChunksFilter
  sorting: Gateway.WorkPackageChunksSorting
  paging: CursorPage
  options?: Pick<QueryHookOptions, 'pollInterval' | 'skip'>
}

export function useWorkPackageChunksByCursor({
  filter,
  sorting,
  paging,
  options,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, fetchMore } = useApolloQuery<
    WorkPackageChunkByCursorQueryData,
    Gateway.QueryWorkPackageChunksByCursorArgs
  >(WorkPackageChunksByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      sorting: [sorting],
    },
    ...options,
    onCompleted(data) {
      const newPageMeta = data.workPackageChunksByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const workPackageChunks =
    data?.workPackageChunksByCursor?.edges.map(edge => edge.node) ?? []

  const loadMore = async () => {
    setLoadingMore(true)

    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setLoadingMore(false)
  }

  return {
    workPackageChunks,
    loading,
    loadMore,
    loadingMore,
    pageInfo: currentPageMeta,
  }
}
