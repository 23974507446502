import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'
import { API } from 'API'
import { SimulatedClassifiedTimeFields } from 'API/ClassifiedTime/GraphQL/Fragments/types'

import { Flex } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v3__'

import { useI18n, useMount } from 'hooks'

import { i18n, TRANSLATIONS } from 'i18n'

import { Content, SimulationData } from './components'
import {
  formItemToSimulationAdditionalTimeEntryPayload,
  mapInitialValuesToFormState,
  processSimulationData,
  processSimulationTotalData,
} from './helpers'
import { Button, ContentContainer, Description } from './styles'
import {
  TimeClassificationSimulationFormState,
  TimeClassificationSimulationModalProps,
} from './types'
import { validator } from './validator'

export function TimeClassificationSimulationModal({
  description,
  subDescription,
  initialValues,
  rulesetId,
  onClose,
}: TimeClassificationSimulationModalProps) {
  const t = useI18n<typeof TRANSLATIONS.timeClassificationSimulationModal>(
    'timeClassificationSimulationModal',
  )

  const [simulationResults, setSimulationResults] = useState<
    SimulatedClassifiedTimeFields[]
  >([])

  const {
    simulateTimeClassification,
    simulating,
  } = API.ClassifiedTime.simulate()

  const formMethods = useForm<TimeClassificationSimulationFormState>({
    mode: 'onBlur',
    resolver: vestResolver(validator),
    defaultValues: mapInitialValuesToFormState(initialValues),
  })

  const {
    watch,
    handleSubmit,
    formState: { isValid },
  } = formMethods

  const watchedEmployeeTimezone = watch('employee.branch.settings.timezone')

  const { timezone } = useMemo(() => {
    if (initialValues) {
      return {
        timezone: initialValues.timezone,
      }
    }

    return {
      timezone: watchedEmployeeTimezone,
    }
  }, [initialValues, watchedEmployeeTimezone])

  useMount(() => {
    if (!initialValues) return

    const input = {
      employeeId: initialValues.employee.id.toString(),
      period: initialValues.period,
      additionalTimeEntries: initialValues.timeEntries.map(
        formItemToSimulationAdditionalTimeEntryPayload,
      ),
      includePending: true,
    }

    simulateTimeClassification(input).then(setSimulationResults)
  })

  const onValidForm = useCallback(
    async ({ period, employee }: TimeClassificationSimulationFormState) => {
      // NOTE: we use form validation only for form with editable inputs
      if (initialValues) return
      if (!employee || !period.from || !period.to) return

      const results = await simulateTimeClassification({
        rulesetId,
        period: {
          start: period.from,
          end: period.to,
        },
        employeeId: employee.value,
      })

      setSimulationResults(results)
    },

    [initialValues, rulesetId, simulateTimeClassification],
  )

  const { rowsData, totalData } = useMemo(() => {
    const rowsData = processSimulationData(simulationResults, timezone)
    const totalData = processSimulationTotalData(simulationResults, timezone)
    return { rowsData, totalData }
  }, [simulationResults, timezone])

  return (
    <Modal.Container isOpen padding={24}>
      <Modal.Close onClose={onClose} />
      <Modal.Title fontSize={'16px'}>{t('title')}</Modal.Title>

      <ContentContainer>
        <Description mb={0}>{description}</Description>
        <Description>{subDescription}</Description>

        <FormProvider {...formMethods}>
          <Content
            disableEdit={!!initialValues}
            isValid={isValid}
            simulating={simulating}
            onSubmit={handleSubmit(onValidForm)}
          />
        </FormProvider>

        <Flex mt={5}>
          <SimulationData
            data={rowsData}
            isLoading={simulating}
            totalData={totalData}
          />
        </Flex>
      </ContentContainer>

      <Modal.Actions gap={2} justifyContent="center" mt={4}>
        <Button secondary onClick={onClose}>
          {i18n('actions.close')}
        </Button>
      </Modal.Actions>
    </Modal.Container>
  )
}
