import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'

import { ShiftQuery } from './GraphQL'

export function useShiftById(variables: Gateway.QueryShiftArgs) {
  const { data, loading, error } = useApolloQuery<
    // Using QueryDate as we are requesting everything the API returns
    QueryData<'shift'>,
    Gateway.QueryShiftArgs
  >(ShiftQuery, {
    variables,
    fetchPolicy: 'network-only',
  })

  const shift = useMemo(() => data?.shift, [data])

  return {
    shift,
    loading,
    error,
  }
}
