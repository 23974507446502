import numeral from 'numeral'

import toNumber from 'lodash/toNumber'

import { LONG_DASH } from 'constants/schedule'

import { i18n } from 'i18n'

// TODO: refactor and move to utils
export function toHoursDays(daysHours, isZeroesForNull = false) {
  if (daysHours?.days && daysHours?.hours) {
    const { days, hours } = daysHours

    return `${numeral(hours).format('0[.]00')}${i18n(
      'leavesSummary.shorts.hours',
    )} (${numeral(days).format('0[.]00')}${i18n('leavesSummary.shorts.days')})`
  }

  return isZeroesForNull
    ? `0${i18n('leavesSummary.shorts.hours')} (0${i18n(
        'leavesSummary.shorts.days',
      )})`
    : LONG_DASH
}

export function getDaysFromHours(hours, dayHourEquivalence) {
  return `(${numeral(hours / dayHourEquivalence).format('0[.]00')} ${i18n(
    'leavesSummary.shorts.days',
  )})`
}

export function getHourDays(
  hours,
  dayHourEquivalence,
  isZeroesForNull = false,
) {
  const numberHours = toNumber(hours)
  return toHoursDays(
    {
      days: numberHours / dayHourEquivalence,
      hours: numberHours,
    },
    isZeroesForNull,
  )
}
