import { create, enforce, test } from 'vest'

import { i18n } from 'i18n'

import { TimeClassificationSimulationFormState } from './types'

export const validator = create(
  (data: TimeClassificationSimulationFormState) => {
    test('employee', i18n('validation.required'), () => {
      enforce(data.employee).isNotBlank()
    })

    test('period', i18n('validation.required'), () => {
      enforce(data.period).isNotEmpty()
    })
  },
)
