import { DateTime } from 'luxon'
import { DeluxeEmployeeSyncStatus } from 'Types/app'

import { AvatarSizeType, DeluxeIntegrationStatuses } from 'constants/ids'

import { i18n } from 'i18n'

export function buildFullName(
  profile: Gateway.Maybe<Pick<Gateway.UserProfile, 'firstName' | 'lastName'>>,
) {
  const firstName = profile?.firstName
  const lastName = profile?.lastName
  if (!firstName && !lastName) return 'No name'
  return `${firstName} ${lastName}`
}

export function buildFullNameOrUndefined(
  profile: Gateway.Maybe<Pick<Gateway.UserProfile, 'firstName' | 'lastName'>>,
) {
  return profile ? buildFullName(profile) : undefined
}

type GetAvatarUrlArgs = {
  avatar: Gateway.Maybe<Gateway.UserProfileAvatar>
  sizeType?: AvatarSizeType
}
export function getAvatarUrl({
  avatar,
  sizeType = AvatarSizeType.Large,
}: GetAvatarUrlArgs) {
  return avatar?.[sizeType]?.url
}
// Deluxe Integration //
interface ServerSyncStateToStatusProps {
  lastSyncAttemptAtDateTime: DateTime | null
  externalId: string | null
  inProgress: boolean
}

export function serverSyncStateToStatus({
  lastSyncAttemptAtDateTime,
  inProgress,
  externalId,
}: ServerSyncStateToStatusProps): DeluxeIntegrationStatuses {
  if (inProgress) return DeluxeIntegrationStatuses.Progress

  if (externalId) return DeluxeIntegrationStatuses.Synced

  // At least you tried
  if (lastSyncAttemptAtDateTime?.isValid)
    return DeluxeIntegrationStatuses.Failed

  // Not sync nor attempt record
  return DeluxeIntegrationStatuses.NeverSynced
}

interface ServerSyncStateToRenderableReturn {
  status: DeluxeIntegrationStatuses
  details: string | null
  error: string | null
  externalId: string | null
}
export function serverSyncStateToRenderable({
  externalId,
  lastSyncAttemptAt,
  lastSuccessSyncAt,
  lastErrorMessage,
  inProgress,
}: DeluxeEmployeeSyncStatus): ServerSyncStateToRenderableReturn {
  const lastSyncAttemptAtDateTime = lastSyncAttemptAt
    ? DateTime.fromISO(lastSyncAttemptAt, {
        zone: 'local',
      })
    : null

  const lastSuccessSyncAtDateTime = lastSuccessSyncAt
    ? DateTime.fromISO(lastSuccessSyncAt, {
        zone: 'local',
      })
    : null

  const status = serverSyncStateToStatus({
    externalId,
    inProgress,
    lastSyncAttemptAtDateTime,
  })

  let details = null

  const failed = status === DeluxeIntegrationStatuses.Failed
  const synced = status === DeluxeIntegrationStatuses.Synced

  if (failed) {
    details = lastSyncAttemptAtDateTime?.isValid
      ? i18n('deluxe.employeePayWebIntegration.feedback.lastAttemptAt', {
          dateTime: lastSyncAttemptAtDateTime.toLocaleString(
            DateTime.DATETIME_MED,
          ),
        })
      : i18n('deluxe.employeePayWebIntegration.feedback.lastAttemptAtUnknown')
  }

  if (synced) {
    details = lastSuccessSyncAtDateTime?.isValid
      ? i18n('deluxe.employeePayWebIntegration.feedback.lastSyncedAt', {
          dateTime: lastSuccessSyncAtDateTime.toLocaleString(
            DateTime.DATETIME_MED,
          ),
          externalId,
        })
      : i18n('deluxe.employeePayWebIntegration.feedback.lastSyncAtUnknown')
  }

  return {
    externalId,
    status,
    details,
    error: failed ? lastErrorMessage : null,
  }
}
