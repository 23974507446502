import { ShiftAPIItem } from 'API'
import { DateTime } from 'luxon'
import { SelectOptionWithId } from 'Types/common'

import { FULFILLMENT_FILTER_OPTIONS, SHIFT_SORTING_OPTIONS } from './constants'

export enum ScheduleViewMode {
  //  Tenant-sepcific view modes
  GardaEmployeeDaily = 'gardaEmployeeDaily',

  // Note: Make Nahdi labels a separate view once we have the design
  // NahdiEmployeeWeeklyLabelCards = 'nahdiEmployeeWeeklyLabelCards',

  // Common view modes
  EmployeeWeeklyCards = 'employeeWeeklyCards',
  ShiftsDaily = 'shiftsDaily',
  ShiftsWeekly = 'shiftsWeekly',
  // Timeline views
  EmployeeHourlyTimeline = 'employeeHourlyTimeline',
  EmployeeWeeklyTimeline = 'employeeWeeklyTimeline',
  EmployeeMonthlyTimeline = 'employeeMonthlyTimeline',
  WorkPackageTimeline = 'workPackageTimeline',
  WorkPackageTimelineGrouped = 'workPackageTimelineGrouped',
}

export interface Holiday {
  id: string
  name: string
}

export interface DayWithPositionsAndHolidays {
  day: DateTime
  unfilledPositionsCount?: number
  filledPositionsCount?: number
  totalPositionsCount?: number
  relatedHolidays: Holiday[]
}

export interface DayWithHolidays {
  day: DateTime
  relatedHolidays: Holiday[]
}

export interface UseDaysWithUnfilledPositionsAndHolidays {
  daysWithUnfilledPositionsAndHolidays: DayWithPositionsAndHolidays[]
  refetchUnfilledPositionsAndHolidays: (
    variables?: Partial<Gateway.QueryUnfilledPositionsArgs> | undefined,
  ) => void
  isLoading: boolean
}

export type OnAssignIntent = (shift: ShiftAPIItem, day: DateTime) => void
export type OnDeleteIntent = (shift: ShiftAPIItem, day: DateTime) => void
export type OnEditIntent = (shift: ShiftAPIItem, day: DateTime) => void
export type OnMoveIntent = (shift: ShiftAPIItem) => void
export type OnArchiveIntent = (shift: ShiftAPIItem, day: DateTime) => void
export type OnOpenShiftLogsModalIntent = (shiftId: ShiftAPIItem['id']) => void

export interface ShiftsWeeklyContext {
  onAssignIntent: OnAssignIntent
  onArchiveIntent: OnArchiveIntent
  onDeleteIntent: OnDeleteIntent
  onEditIntent: OnEditIntent
  onMoveIntent: OnMoveIntent
  onOpenShiftLogsModalIntent: OnOpenShiftLogsModalIntent
}

export type ShiftSortingOptions = typeof SHIFT_SORTING_OPTIONS[0]
export type ShiftFulfillmentOptions = typeof FULFILLMENT_FILTER_OPTIONS[0]

export type ScheduleAreas = {
  locations: SelectOptionWithId
  departments?: SelectOptionWithId[]
  jobs?: SelectOptionWithId[]
}
