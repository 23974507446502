import { useCallback, useState } from 'react'

import { DEFAULT_JSON_API_CURSOR_PAGING } from 'API/services/JsonApi'
import {
  getCursorPageMeta,
  transformResponseToEntities,
} from 'API/services/utils'

import { useAppContext } from 'hooks'

import { i18n } from 'i18n'

import { showError } from 'services/API'
import apiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'
import Utils from 'services/Utils'

import {
  EmployeePickerApiFilters,
  EmployeePickerApiReturn,
  EmployeePickerParameters,
  TimeEntriesFilters,
} from './types'

import { EMPLOYEES_JSON_API_TYPE } from '../Json'

export type LazyEmployeesArgs = {
  filters: Omit<EmployeePickerApiFilters, 'nameOrCode'>
  parameters: EmployeePickerParameters
  timeEntriesFilters?: TimeEntriesFilters
} & {
  page?: JsonApi.CursorPagingInput
}

// TODO: switch GW
export function useLazyEmployeesByCursor({
  page,
  filters,
  timeEntriesFilters,
  parameters,
}: LazyEmployeesArgs) {
  const [fetching, setFetching] = useState(false)
  const { company } = useAppContext()

  const [employees, setEmployees] = useState<EmployeePickerApiReturn[]>([])

  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const loadEmployees = useCallback(
    async ({
      nameOrCode,
      endCursor,
    }: {
      nameOrCode?: string
      endCursor?: string
    }) => {
      try {
        setFetching(true)
        const response: JsonApi.Response = await apiCall({
          method: 'GET',
          endpoint: `/companies/${company.coreId}/employees`,
          query: {
            include,
            // Not all filters connected now
            filter: {
              ...(nameOrCode && {
                name_or_code: Utils.JsonApi.iLike(nameOrCode),
              }),
              ...(filters.areas && Utils.JsonApi.areasFilter(filters.areas)),
            },
            ...(parameters.includeArchived && {
              include_archived: parameters.includeArchived,
            }),
            ...(timeEntriesFilters && {
              time_entries_filter: {
                with_time_entries: { eq: timeEntriesFilters.withTimeEntries },
                kind: { in: [timeEntriesFilters.kind] },
                start_on: {
                  between: timeEntriesFilters.between,
                },
              },
            }),
            page: {
              ...(page ?? DEFAULT_JSON_API_CURSOR_PAGING),
              ...(endCursor && { starting_after: endCursor }),
            },
            sort: company.identity.Etfo ? 'name' : undefined,
          },
        })

        if (!response?.ok) {
          showToast({
            type: 'error',
            content: i18n('sections.people'),
            title: i18n('common.somethingWentWrong'),
          })
          return []
        }

        const {
          entities,
        } = transformResponseToEntities<EmployeePickerApiReturn>({
          response,
          jsonApiType: EMPLOYEES_JSON_API_TYPE,
        })

        setEmployees(prevEmployees =>
          endCursor ? [...prevEmployees, ...entities] : entities,
        )
        setCurrentPageMeta(getCursorPageMeta(response))
        return entities
      } catch (error) {
        showError(error)
        return []
      } finally {
        setFetching(false)
      }
    },
    [
      company.coreId,
      company.identity.Etfo,
      filters.areas,
      page,
      parameters.includeArchived,
      timeEntriesFilters,
    ],
  )

  return {
    employees,
    fetching,
    loadEmployees,
    currentPageMeta,
  }
}

const include = ['profile', 'branch'].join()
