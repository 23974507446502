import PropTypes from 'prop-types'

import { WeekdayNumbers } from 'luxon'

import { StaffStatus } from './jsonAPI'

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export const APP_TYPES = {
  employer: 'employer',
  employee: 'employee',
} as const

export const ACCESS_LEVEL = {
  owner: 'owner',
  admin: 'admin',
  manager: 'manager',
  employee: 'employee',
} as const

export const TIP_ARROW_TYPES = {
  LEFT: 'left',
  TOP: 'top',
} as const

export enum InviteTabs {
  Admin = 'admin',
  BranchManager = 'branchManager',
  DepartmentManager = 'departmentManager',
  Employee = 'employee',
}

export const ACCESS_TABS = {
  branches: 'accessLevels.branches',
  departments: 'accessLevels.departments',
} as const

export const FormikPropTypes = {
  validateOnChange: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  isInitialValid: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  initialValues: PropTypes.object,
  validationSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  validate: PropTypes.func,
  component: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  enableReinitialize: PropTypes.bool,
}

export const TRANSLATIONS = {
  en: {},
  fr: {},
  es: {},
}

export const FLUENCIES = {
  low: 'Low',
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  conversational: 'Conversational',
  fluent: 'Fluent',
}

export const AUTH_SCREENS = {
  signIn: 'signIn',
  signUp: 'signUp',
  forget: 'forget',
}

export const FILTER_BY_COND = {
  and: 'and',
  or: 'or',
}

export const HATHOR_TRANSACTION_TYPES = {
  companyToUser: 'companyToUser',
  userToUser: 'userToUser',
  userToCompany: 'userToCompany',
  mint: 'mint',
  melt: 'melt',
}

export const HATHOR_TICKER = 'HTR'
export const HTR_TO_COMPANY_TOKEN_RATIO = 100

export const HATHOR_TRANSACTION_CATEGORY = {
  outbound: 'outbound',
  inbound: 'inbound',
}

export const HATHOR_ACTOR = {
  user: 'user',
  company: 'company',
}

export const HATHOR_PRODUCT_REQUEST_STATUSES = {
  placed: 'placed',
  approved: 'approved',
  completed: 'completed',
  rejected: 'rejected',
  deleted: 'deleted',
}

export const COMPANY_IDENTITIES = {
  nahdi: 'nahdi',
  demo: 'demo',
  sobeys: 'sobeys',
  etfo: 'etfo',
}

export const AVATAR_SIZE_TYPES = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
}

export const GENDERS = {
  male: 'MALE',
  female: 'FEMALE',
}

export const FILE_UPLOAD_KINDS = {
  branchImage: 'branch_image',
  profileAvatar: 'profile_avatar',
}

export const CURRENT_STATE_WIDGETS = {
  currentlyClockedIn: StaffStatus.CurrentlyWorking,
  scheduledToday: 'scheduledToday',
  activeUsers: StaffStatus.Active,
  pendingUsers: StaffStatus.Pending,
  archivedUsers: StaffStatus.Archived,
  pendingTradeRequests: 'pendingTradeRequests',
  pendingCoverRequests: 'pendingCoverRequests',
  pendingTimeoffRequests: 'pendingTimeoffRequests',
}

export const DEVIATION_STATE_WIDGETS = {
  lateOcurrences: 'lateOcurrences',
  absences: 'absences',
  pendingTimecards: 'pendingTimecards',
}

export const UNFILLED_SHIFTS_WIDGET = 'unfilledShifts'
export const WEEKLY_HOURS_WIDGET = 'weeklyHours'
export const LOCAL_STORAGE_KEYS = { searchQueries: 'searchQueries' }

export const SHIFT_POSITION_FILTER = {
  empty: 'empty',
  partial: 'partial',
  full: 'full',
}

export const COVER_REQUEST_STATUSES = {
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
}

export const TRADE_REQUEST_STATUSES = {
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
}

export const WEEKDAYS: WeekdayNumbers[] = [1, 2, 3, 4, 5, 6, 7]

// TODO: Replace enums with codegenerated enum when available
export enum CompanyIdentity {
  Nahdi = 'nahdi',
  Demo = 'demo',
  Sobeys = 'sobeys',
  Etfo = 'etfo',
  Deluxe = 'deluxe',
  Keywords = 'keywords',
  Hrwize = 'hrwize',
  Aldo = 'aldo',
  Netease = 'netease',
  Lemon = 'lemon',
  Garda = 'garda',
  Fps = 'fps',
  Icc = 'icc',
  Tbs = 'tbs',
  LQ = 'lq',
  DrMax = 'drmax',
}

export enum UserAccessLevels {
  Owner = 'owner',
  Admin = 'admin',
  Manager = 'manager',
  Employee = 'employee',
}

// This looks same as UsersCountStatusType, but it is not. Only replace with GW type when the Employee Schema is ready
export enum EmployeeStatuses {
  Active = 'active',
  Archived = 'archived',
  Deleted = 'deleted',
  CurrentlyWorking = 'currently_working',
}

export enum AvatarSizeType {
  Xsmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Xlarge = 'xlarge',
}

export enum CustomLabelDayKinds {
  WeeklyVacation = 'weekly_vacation',
  AnnualVacation = 'annual_vacation',
  UnpaidVacation = 'unpaid_vacation',
  PaidVacation = 'paid_vacation',
  Holiday = 'holiday',
}

export enum ReferenceYearEndDateKinds {
  Static = 'static',
  HireDate = 'hire_date',
}

export enum LeavePolicyKinds {
  Unlimited = 'unlimited',
  Manual = 'manual',
}

export enum WageType {
  Hour = 'hour',
  Year = 'year',
}

export enum ShiftSortingKinds {
  unfilledFirst = 'unfilledFirst',
  filledFirst = 'filledFirst',
  newestFirst = 'newestFirst',
  latestFirst = 'latestFirst',
  nameAscending = 'nameAscending',
  nameDescending = 'nameDescending',
}

export const MAX_NAME_LENGTH = 30

export enum DeluxeIntegrationStatuses {
  NeverSynced = 'NeverSynced',
  Synced = 'Synced',
  Failed = 'Failed',
  Progress = 'Progress',
}

export enum AccessLevel {
  Owner = 'owner',
  Admin = 'admin',
  Manager = 'manager',
  Employee = 'employee',
  BranchManager = 'branchManager',
  DepartmentManager = 'departmentManager',
}

export enum ViewerAccessTypes {
  AccessLevel = 'accessLevel',
  CurrentAccessLevel = 'currentAccessLevel',
}

export const MONTH_NAMES = [
  'months.january',
  'months.february',
  'months.march',
  'months.april',
  'months.may',
  'months.june',
  'months.july',
  'months.august',
  'months.september',
  'months.october',
  'months.november',
  'months.december',
] as const

export const DAY_NAMES = [
  'days.monday',
  'days.tuesday',
  'days.wednesday',
  'days.thursday',
  'days.friday',
  'days.saturday',
  'days.sunday',
] as const

export const DAY_NAMES_FROM_SUN = [
  DAY_NAMES[DAY_NAMES.length - 1],
  ...DAY_NAMES.slice(0, DAY_NAMES.length - 1),
]

export enum AvailabilityRequestTypes {
  Edit = 'edit',
  Create = 'create',
  Delete = 'delete',
}

export enum ScheduleConflictActionTypes {
  None = 'none',
  Unassign = 'unassign',
  Split = 'split',
  Error = 'error',
}

export enum LeaveDurationTypes {
  WholeDay = 'wholeDay',
  Partial = 'partial',
}

export enum TimeoffStates {
  All = 'all',
  Accepted = 'accepted',
  Deleted = 'deleted',
  Pending = 'pending',
  Rejected = 'rejected',
}
