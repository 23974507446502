import { secondsToStartEnd } from './utils'

import {
  FormTimesheetRecord,
  TimeClassificationSimulationFormState,
  TimeClassificationSimulationModalProps,
} from '../types'

export function formItemToSimulationAdditionalTimeEntryPayload(
  timeEntry: FormTimesheetRecord,
): Gateway.TestClassifyTimeAdditionalTimeEntryInput {
  const { timeType } = timeEntry
  const { startAt, endAt } = secondsToStartEnd({
    startDate: timeEntry.startDate,
    startSeconds: timeEntry.startSeconds,
    endSeconds: timeEntry.endSeconds,
    timezone: timeEntry.position?.data?.location?.timezone || 'local',
  })
  const { location, department, job } = timeEntry.position?.data ?? {}

  return {
    id: timeEntry?.id ? timeEntry.id.toString() : undefined,
    locationId: location?.id,
    departmentId: department?.id,
    jobId: job?.id,
    startAt: startAt.toISO(),
    endAt: endAt.toISO(),
    timeRanges: [
      {
        startAt: startAt.toISO(),
        endAt: endAt.toISO(),
        timeTypeId: timeType?.value!,
      },
    ],
  }
}

export function mapInitialValuesToFormState(
  initialValues: TimeClassificationSimulationModalProps['initialValues'],
): TimeClassificationSimulationFormState {
  return {
    period: initialValues?.period
      ? {
          from: initialValues?.period.start,
          to: initialValues?.period.end,
        }
      : {},
    employee: initialValues?.employee
      ? {
          value: initialValues.employee.id.toString(),
          label: initialValues.employee.name,
        }
      : null,
  }
}
