import { ScheduleQuery } from 'API/Schedule/GraphQL/Queries/Schedule'
import { ScheduleQueryData } from 'API/Schedule/GraphQL/Queries/Schedule/types'
import { useApolloQuery } from 'API/services/Apollo'

type Props = {
  id: string
  directives: {
    includeWorkPackageChunks?: boolean
  }
}
export function useSchedule({ id, directives }: Props) {
  const { data, loading, error } = useApolloQuery<
    ScheduleQueryData,
    Gateway.QueryScheduleArgs & {
      includeWorkPackageChunks: boolean
    }
  >(ScheduleQuery, {
    variables: {
      id,
      includeWorkPackageChunks: Boolean(directives.includeWorkPackageChunks),
    },
    skip: !id,
  })

  return {
    schedule: data?.schedule,
    loading,
    error,
  }
}
