import styled from 'styled-components'
import { flexbox, grid, layout, space } from 'styled-system'

import { gap } from 'theme/styles'

import { CommonProps } from './types'

type HeaderProps = CommonProps

export const HeaderV2 = styled.div<HeaderProps>`
  width: 100%;
  min-height: ${({ theme }) => theme.sizes.pageHeader};
  padding: 12px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.layout.bg};
  border-bottom: 1px solid ${({ theme }) => theme.layout.border};

  ${space};
  ${layout};
  ${flexbox};
  ${gap}
  ${grid};
`
