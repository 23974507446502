import styled from 'styled-components'
import { size, SizeProps } from 'styled-system'

import { skeletonCss } from 'theme/styles'

export const SkeletonAvatar = styled.div<SizeProps>`
  border-radius: 4px;
  ${size}
  ${skeletonCss}
`
