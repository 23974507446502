import { useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  WorkPackageEventsSummariesByCursorQuery,
  WorkPackageEventsSummariesByCursorQueryData,
} from './GraphQL'

type Props = {
  filter: Gateway.WorkPackageEventsSummariesFilter
  sorting: Gateway.WorkPackageEventSorting
  paging: CursorPage
  statisticsFilter?: Gateway.WorkPackageEventsSummaryStatisticsFilter
  skip?: boolean
}

export function useWorkPackageEventsSummariesByCursor({
  filter,
  sorting,
  paging,
  statisticsFilter,
  skip,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, fetchMore } = useApolloQuery<
    WorkPackageEventsSummariesByCursorQueryData,
    Gateway.QueryWorkPackageEventsSummariesByCursorArgs & {
      statisticsFilter?: Gateway.WorkPackageEventsSummaryStatisticsFilter
    }
  >(WorkPackageEventsSummariesByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      sorting: [sorting],
      statisticsFilter,
    },
    skip,
    onCompleted(data) {
      const newPageMeta = data.workPackageEventsSummariesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const workPackageEventsSummaries =
    data?.workPackageEventsSummariesByCursor.edges.map(edge => edge.node) ?? []

  const loadMore = async () => {
    setLoadingMore(true)

    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setLoadingMore(false)
  }

  return {
    workPackageEventsSummaries,
    loading,
    loadMore,
    loadingMore,
    pageInfo: currentPageMeta,
  }
}
