import styled, { css, keyframes } from 'styled-components'
import { layout, LayoutProps } from 'styled-system'
import { system } from '@styled-system/core'

export type GapProps = {
  gap?: number | string | number[]
}
export const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
})

// =====================================================================================
// Skeleton
// =====================================================================================

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`

// TODO: theme colors
export const skeletonCss = () => css`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: ${shine} 2s linear infinite;
`
export const SkeletonBlock = styled.div<LayoutProps>`
  ${layout}
  ${skeletonCss}
`

export const disableScrollBarCss = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
