// TODO: set up a proper structure here, speak with AV
import { persistState } from '@storeon/localstorage'
import { createStoreon } from 'storeon'

import camelCase from 'lodash/camelCase'

import Shared from 'services/Shared'

import currentStateModule, { CURRENT_STATE_KEY } from './Dashboard/CurrentState'
import deviationsModule, {
  DEVATIONS_STATE_KEY,
} from './Dashboard/DeviationsState'
import {
  HISTORIC_DEMAND_STATE_KEY,
  historicDemandModule,
} from './Dashboard/HistoricDemand'
import hoursModule, { HOURS_STATE_KEY } from './Dashboard/Hours'
import unfilledPositionsModule, {
  UNFILLED_STATE_KEY,
} from './Dashboard/UnfilledPositions'
import {
  GARDA_REPORTS_STATE_KEY,
  gardaReportsModule,
} from './Garda/GardaReports'
import { LOCALE_KEY, localeModule } from './Locale/locale'
import { localenModalModule } from './Locale/localeModal'
import { ALDO_PAYROLL_SYNC_KEY, aldoPayrollSyncModule } from './aldoPayrollSync'
import { DEMAND_FORECASTING_KEY, demandForecastingModule } from './BI'
import { APP_NAME } from './constants'
import { COPY_SHIFTS_KEY, copyShiftsModule } from './copyShifts'
import { customFieldEditModule } from './customFieldEdit'
import {
  DELUXE_PAYROLL_SYNC_KEY,
  deluxePayrollSyncModule,
} from './deluxePayrollSync'
import { confirmationModalModule } from './modules'
import {
  MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY,
  moveSchedulesFromShiftModule,
} from './MoveSchedulesFromShift'
import { nlpInferenceModule } from './nlpInference'
import { PEOPLE_KEY, peopleModule } from './People'
import { QUERY_PARAMS_STATE_KEY, queryParamsModule } from './QueryParams'
import { RULE_BUILDER_KEY, ruleBuilderModule } from './RuleBuilder'
import {
  EVALUATED_TIME_SYNC_KEY,
  evaluatedTimeSyncModule,
} from './Synchronization'
import {
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
  classifiedTimeTemporalWorkflowModule,
  EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
  evaluatedTimeTemporalWorkflowModule,
} from './TemporalWorkflow'
import {
  TIMELINE_VIEW_SETTINGS_MODAL_KEY,
  timelineViewSettingsModalModule,
} from './TimelineViewSettingsModal'
import { Store, StoreonEvents, StoreonState } from './types'

export const storeon: Store = createStoreon<StoreonState, StoreonEvents>([
  currentStateModule,
  deviationsModule,
  hoursModule,
  unfilledPositionsModule,
  queryParamsModule,
  confirmationModalModule,
  deluxePayrollSyncModule,
  aldoPayrollSyncModule,
  nlpInferenceModule,
  localeModule,
  localenModalModule,
  historicDemandModule,
  demandForecastingModule,
  ruleBuilderModule,
  customFieldEditModule,
  gardaReportsModule,
  copyShiftsModule,
  classifiedTimeTemporalWorkflowModule,
  evaluatedTimeTemporalWorkflowModule,
  evaluatedTimeSyncModule,
  moveSchedulesFromShiftModule,
  timelineViewSettingsModalModule,
  peopleModule,
  persistState(
    [
      CURRENT_STATE_KEY,
      DEVATIONS_STATE_KEY,
      HOURS_STATE_KEY,
      UNFILLED_STATE_KEY,
      HISTORIC_DEMAND_STATE_KEY,
      QUERY_PARAMS_STATE_KEY,
      DELUXE_PAYROLL_SYNC_KEY,
      ALDO_PAYROLL_SYNC_KEY,
      LOCALE_KEY,
      DEMAND_FORECASTING_KEY,
      RULE_BUILDER_KEY,
      GARDA_REPORTS_STATE_KEY,
      COPY_SHIFTS_KEY,
      EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
      CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
      EVALUATED_TIME_SYNC_KEY,
      MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY,
      TIMELINE_VIEW_SETTINGS_MODAL_KEY,
      PEOPLE_KEY,
    ],
    {
      // TODO: persist state per user?
      key: camelCase(APP_NAME),
    },
  ),
])

Shared.setStoreOn(storeon)
